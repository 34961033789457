import React from 'react'
import Layout from '../components/layout'
import errorImage from '../images/404-image.png'

const NotFound = () => {
	return (
		<Layout title="404 Not Found">
			<div className="container-fluid">
				<div className="my-row justify-content-center align-content-center not-found mt-5">
					<div className="col-12 mt-2">
						<div className="my-row justify-content-center">
							<h1><b>404</b></h1>
						</div>
						<div className="my-row justify-content-center">
							<h3><b>Entschuldigung</b>, die Seite die Du suchst konnte nicht gefunden werden!</h3>
						</div>
						<div className="my-row justify-content-center my-3">
							<div className="col-10">
								<img src={errorImage} width='90%' height='90%' alt="Seite die Du suchst konnte nicht gefunden werden"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}
 
export default NotFound